Vue.use(VueTables.ClientTable);

Vue.component('reportTableViewSubRow',
    {
        props:['row','columns','headings'],
        computed:{
            myoptions:function(){
                //console.log('Options ',this.headings)
                return {
                    filterable: false,
                    columnsDropdown: false,
                    preserveState: false,
                    headings: this.headings,
                    uniqueKey:'internalId',
                    rowClassCallback: function(row){
                        //console.log('.rowClassCallback',row.is_footer);
                        if(row.is_footer){
                            console.log('row ok ');
                            return 'footer-row';
                        }
                        return false;
                    }
                }
            },
            rows:function () {
                console.log('Calculated SubRows',this);
                return this.row.$subrows;
            },

        },
        template:`<v-client-table :columns="columns" :data="rows" :options="myoptions"  ></v-client-table>`
    }
);

Vue.component('reportTableView',{
    props:{
        tablename:{
            type: String,
            required: true
        },
        table: {
            type: Object,
            required: true
        },
        filtrable:{
            type: Boolean,
            default: true
        },
        columnsDropdown:{
            type: Boolean,
            default: false
        },
        paginationChuck:{
            type: Number,
            default: 15
        },
        perPage:{
            type: Number,
            default: 10
        },
    },
    computed:{
        options:function () {
            return {
                filterable: this.filterable,
                columnsDropdown: this.columnsDropdown,
                preserveState:true,
                headings: this.headings,
                childRowTogglerFirst: this.childRowTogglerFirst,
                //childRow: this.childRow ? 'reportTableViewSubRow' : null,
                pagination: { chunk:this.paginationChuck },
                uniqueKey:'internalId',
                texts:{
                    count:`Mostrando {from} - {to} de {count} ${this.tr('Rows')} |{count} ${this.tr('Rows')} | Una ${this.tr('Row')}`,
                    filter:this.tr('Search')+':',
                    limit:'',
                    filterPlaceholder:this.tr('Write a value'),
                    noResults:this.tr('No Sales Orders were found'),
                    page:this.tr('Page')+":", // for dropdown pagination
                    filterBy: 'Filtrado por {column}', // Placeholder for search fields when filtering by column
                    loading:this.tr('Loading')+'...', // First request to server
                    defaultOption:'Select {column}', // default option for list filters,
                },
                rowClassCallback: function(row){
                    //console.log('.rowClassCallback',row.is_footer);
                    if(row.is_footer){
                        console.log('row ok ');
                        return 'footer-row';
                    }
                    return false;
                }
            }
        },
        childRowTogglerFirst:function(){
            if(this.table.detail_columns.length>0)
                return true
            return false
        },
        childRow:function(){
            if(this.table.detail_columns.length>0)
                return true;
            return false
        },
        headings:function() {
            return this.table.column_titles_dict;
        },
        columns:function () {
            //return this.table.columns;
            let columns = [];
            if(this.table.columns.length>0)
                columns=this.table.columns.filter( (x) =>{
                    if(this.table.columns_dict[x] && this.table.columns_dict[x])
                        return this.table.columns_dict[x].visible===true;
                    return false
                });
            //console.log(columns);
            return columns;
        },
        subheadings:function() {
            let subhead= {}
            Object.values(this.table.detail_columns_dict).map((x)=>{
                subhead[x.col]=this.tr(x.label);
            });
            return subhead;

        },
        subcolumns:function () {
            return this.table.detail_columns;
            //let columns = this.table.columns.filter(x=>this.table.columns_dict[x].visible===true);
            //console.log(columns);
            //return columns;
        },
        rows:function () {
            let rows = this.table.rows;
            if(this.table.footer.length>0){
                if(rows.length>=this.perPage){
                    let iter = 1;
                    let max_iter = parseInt(rows.length/this.perPage);
                    if(rows.length%this.perPage>0)
                        max_iter +=1;
                    if(this.table.footer.length%max_iter>0)
                        max_iter +=1
                    max_iter = max_iter + parseInt(this.table.footer.length*max_iter/this.perPage);
                    let insert_row=false;
                    while(iter<=max_iter+1){
                        let row_counter =0;
                        for (let footer_row of this.table.footer) {
                            let temp_footer = Object.assign({is_footer:true},footer_row);
                            let insert_index=parseInt(iter*(this.perPage)-this.table.footer.length+row_counter);
                            //console.log('Iterator',iter,max_iter,insert_index,rows.length);
                            if(insert_index<rows.length)
                                rows.splice(insert_index,0,temp_footer );
                            else{
                                insert_row=true;
                                rows.push(temp_footer );
                            }
                            row_counter++;
                        }
                        if(insert_row)
                            break;
                        iter++;
                    }
                }
                else{
                    for (let footer_row of this.table.footer){
                        let temp_footer = Object.assign({is_footer:true},footer_row);
                        rows.push(temp_footer);
                    }
                }
            }
            return rows;
        }
    },
    template:`<div>
                    <template v-if="childRow">
                        <v-client-table :columns="columns" :data="rows" :options="options" :ref="tablename" >
                            <template slot="child_row"  slot-scope="props" v-bind:subcolumns="subcolumns" v-bind:subheadings="subheadings">
                                <reportTableViewSubRow :row="props.row" :columns="subcolumns" :headings="subheadings" />
                            </template>
                        </v-client-table>
                    </template>
                    <template v-else>
                        <v-client-table :columns="columns" :data="rows" :options="options" :ref="tablename"></v-client-table>
                    </template>
              </div>`
});

Vue.component('reportComponent',{
    props:["reportName"],
    data: function () {
            return {
                params:{},
                loading:false,
                report:null
            }
    },
    methods:{
        getReportName(){
            let splitReportName = this.reportName.split("_")
            return splitReportName[0]
        },
        runReport: function(parameters){
            let self = this;
            return new Promise((resolve, reject) => {
                this.loading = true;
                self.report = null;
                axios.post('/ecommerce/api/runReport', {
                    reportName: self.getReportName(),
                    params: parameters
                })
                .then(function (response) {
                    self.report = Object.freeze(response.data.report);
                    //self.report_response = Object.freeze(response.data);
                    self.loading = false;
                    resolve(true);
                })
                .catch(function (error) {
                    console.log(error);
                    self.loading = false;
                    reject(false)
                });
            });
        },
        dataURIToBlob(b64Data, contentType='', sliceSize=512) {


            console.log(b64Data.split(',')[1]);
            contentType = b64Data.split(',')[0];
            var byteCharacters = atob(b64Data.split(',')[1]);
            function decode_utf8(s) {
                return decodeURIComponent(escape(s));
            }
            var byteCharacters = decode_utf8(byteCharacters);
            var byteArrays = [];

            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);

                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                var byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }

            var blob = new Blob(byteArrays, {type: contentType});
            return blob;


        },
        downloadDataAsFile: function (filename, data, type) {
            let utf8_blob =this.dataURIToBlob(data,type)
            if(window.navigator.msSaveOrOpenBlob) {
                //console.log("If TEST",type,blob);
                window.navigator.msSaveBlob(utf8_blob, filename);
            }
            else{
                //console.log("else TEST",type,blob);
                let elem = window.document.createElement('a');
                let href = window.URL.createObjectURL(utf8_blob);
                elem.href = href
                elem.download = filename;
                document.body.appendChild(elem);
                elem.click();
                document.body.removeChild(elem);
                window.URL.revokeObjectURL(href);
            }
        },
        exportReport: function(parameters){
            let self = this;
            return new Promise((resolve, reject) => {
                this.loading = true;
                axios.post('/ecommerce/api/exportReport', {
                    reportName: self.reportName,
                    params: parameters
                })
                .then(function (response) {
                    console.log(response);
                    self.loading = false;
                    self.downloadDataAsFile(response.data.filename,response.data.reportData,'text/excel;encoding:UTF-8');
                    resolve(true);
                })
                .catch(function (error) {
                    console.log("Error in exportReport Rquest", error);
                    self.loading = false;
                    reject(false);
                });
            });
        }
    },
    computed:{
        reportTables:function () {
            if(this.report)
                return Object.keys(this.report);
            return [];
        }
    },
    template:`<div>
                 <slot name="parameters" :runAction="runReport" :exportAction="exportReport" :params="params" :loading="loading"></slot> 
                 <template v-if="loading" >
                        <h4 class="center">{{tr('Loading')}}</h4>
                 </template>
                 <template v-else>
                     <div :key="'reportRenderContainer-'+getReportName()" id="genericReportView" :ref="'reportRenderContainer-'+reportName" class="row"  style="overflow-x: auto;">
                          <template v-if="reportTables.length>0" v-for="table in reportTables">
                                <reportTableView :table="Object.freeze(report[table])" :key="reportName+table" :tablename="table"></reportTableView>
                          </template>
                     </div>           
                 </template>     
             </div>`,
    template2:`<div>
                 <slot name="parameters" :runAction="runReport" :exportAction="exportReport" :params="params" :loading="loading"></slot> 
                 <iframe :key="'reportRenderContainer-'+reportName" id="genericReportView" :ref="'reportRenderContainer-'+reportName" class="col-sm-12" width="100%" height="100%"/>                
             </div>`
});